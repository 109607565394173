@import "../../assets/style/variable.module.scss";

.wrapper {
  margin-top: 150px;

  .sliderWrapper {
    min-height: 420px;
  }

  // .card {
  //   background: #f5f2ed;
  //   min-height: 400px;
  //   border-radius: 10px;
  //   position: relative;
  //   opacity: 0.4;
  //   transition: all 0.2s ease;
  //   cursor: pointer;

  //   svg {
  //     position: absolute;
  //     top: 24px;
  //     left: 24px;

  //     path {
  //       fill: #858585;
  //       transition: all 0.2s ease;
  //     }
  //   }

  //   p {
  //     max-width: 230px;
  //     margin-top: 24px;
  //     max-height: 100px;
  //     min-height: 100px;
  //   }

  //   .content {
  //     position: absolute;
  //     bottom: 57px;
  //     left: 24px;
  //   }

  //   .title {
  //     display: flex;
  //     align-items: center;

  //     h5 {
  //       font-size: 36px;
  //       color: #413d45;
  //       line-height: 40px;
  //       margin-left: 12px;
  //       font-weight: 700;
  //       max-width: 170px;
  //     }

  //     img {
  //       width: 75px;
  //       height: 75px;
  //       object-fit: cover;
  //       object-position: center;
  //       border-radius: 20px;
  //     }
  //   }
  // }

  .card {
    min-height: 400px;
    background: #f5f2ed;
    border: 1px solid #f1e0c4;
    border-radius: 20px;
    position: relative;
    display: flex;
    box-shadow: 0px 0 30px rgba($color: #e3ba75, $alpha: 0.2);
    opacity: 0.4;
    .topCard {
      position: absolute;
      top: -1px;
      left: -1px;
      height: 160px;

      width: calc(100% + 2px);
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 10px 30px rgba($color: #e3ba75, $alpha: 0.2);

      .topContent {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;

        display: flex;
        padding: 30px;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 20px;
        svg {
          width: 35px;
          height: 35px;
          path {
            fill: #f5f2ed;
          }
        }

        .text {
          display: flex;
          align-items: center;
          gap: 12px;

          h5 {
            font-size: 28px;
            max-width: 180px;
            color: #f5f2ed;
            font-weight: 700;
          }
        }

        img {
          width: 90px;
          height: 90px;
          object-fit: cover;
          object-position: center;
          border-radius: 5px;
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba($color: #f59a13, $alpha: 0.8);
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        // background: linear-gradient(
        //   0deg,
        //   rgba(245, 154, 19, 1) 30%,
        //   rgba(245, 154, 19, 0) 100%
        // );
      }

      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 0;
        border-radius: 20px;
        filter: blur(8px);
      }
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      height: 400px;
      padding: 0 30px 30px 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: relative;

      p {
        font-size: 20px;
        line-height: 32px;
        position: absolute;
        left: 30px;
        top: 160px + 30px;
        max-width: 400px;
        color: #413d45;
      }

      a {
        position: absolute;
        right: 30px;
        bottom: 30px;
        font-weight: 700;
        padding: 11px 16px;
        box-sizing: border-box;
        border-radius: 8px;
        background: $--primary-orange;
        color: white;
        border: 1px solid #ffbc59;
      }
    }
  }

  .active {
    opacity: 1;
    margin-top: 12px;

    svg {
      path {
        fill: #ff7e1d;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .wrapper {
    margin-top: 75px;
    box-sizing: border-box;

    span {
      display: block;
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .sliderWrapper {
      overflow-x: hidden;
      max-width: 100vw;
    }
  }
}
